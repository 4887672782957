<template>
  <div>
    <div class="container">
      <div class="bill-content">
        <div class="top-tip">充值手机号<span>(请确保准确无误)</span></div>
        <div class="phone-box">
          <div class="content-box">
            <div class="left-box">
              <div class="tel-input">
                <!-- <van-field
                  type="tel"
                  v-model="mobile"
                  clearable
                  placeholder="请输入手机号"
                  clear-trigger="always"
                  maxlength="11"
                  @input="changeTel"
                  @blur="inputBlur"
                /> -->
                <van-popover
                  v-model="showPopover"
                  trigger="click"
                  placement="bottom-start"
                  class="account-popover"
                >
                  <template #reference>
                    <van-field
                      type="tel"
                      v-model="mobile"
                      clearable
                      placeholder="请输入手机号"
                      clear-trigger="always"
                      maxlength="11"
                      @input="changeTel"
                      @blur="inputBlur"
                    />
                  </template>
                  <div class="account-history">
                    <div class="list-box">
                      <div
                        class="list-item"
                        v-for="item in accountHistoryList"
                        :key="item.recharge"
                      >
                        <span
                          class="text one-line-hidden"
                          @click="selectAccount(item)"
                          >{{ item.recharge }}</span
                        >
                        <span class="icon"
                          ><van-icon name="cross" @click="delAccount(item)"
                        /></span>
                      </div>
                    </div>
                  </div>
                </van-popover>
              </div>
            </div>
            <div class="right-box">
              <img src="../../assets/images/tel-user.png" alt="" />
            </div>
          </div>
          <div v-if="mobileAddress" class="tel-tip">{{ mobileAddress }}</div>
        </div>
        <div class="bill-coupon">
          <van-cell-group inset :border="false">
            <van-cell title="优惠券" is-link @click="clickCouponCell">
              <img
                slot="icon"
                class="left-icon"
                src="../../assets/images/telcoupon.png"
                alt=""
              />
              <template #right-icon>
                <div class="coupon-cell">
                  <span class="amount" v-if="selectCouponData"
                    >-￥{{
                      mathManage.formatMoney(selectCouponData.amount, 2)
                    }}</span
                  >
                  <span class="tip" v-else> 可用优惠券{{ couponCount }}个</span>
                  <van-icon class="tip arrow-tip" name="arrow" />
                </div>
              </template>
            </van-cell>
          </van-cell-group>
        </div>
        <div class="product-property">
          <h3 class="title">商品规格</h3>
          <div class="property-list">
            <div
              v-for="item in propertyList"
              :key="item.specId"
              :class="
                item.specId == selectProperty.specId
                  ? 'property-item-box active'
                  : 'property-item-box'
              "
            >
              <div class="property-item" @click="changeProperty(item)">
                {{ item.specName }}
                <div class="property-item-icon">
                  <img src="../../assets/images/gou.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <h3 class="title facevalue-title">面额</h3>
          <div class="attr-list">
            <div
              :class="
                item.attrId == selectAttr.attrId
                  ? 'attr-item-box active'
                  : 'attr-item-box'
              "
              v-for="item in attrList"
              :key="item.attrId"
            >
              <div class="attr-item" @click="changeAttr(item)">
                <div class="attr-item-icon">
                  <img src="../../assets/images/gou.png" alt="" />
                </div>
                <div v-if="item.attrTag" class="attr-tag one-line-hidden">
                  {{ item.attrTag }}
                </div>
                <div class="item attr-name one-line-hidden">
                  {{ item.attrName }}
                </div>
                <div class="item attr-price">
                  <span class="unit">￥</span>
                  <span class="price">{{
                    mathManage.formatMoney(item.product.productSalePrice, 2)
                  }}</span>
                </div>
                <div class="item attr-facevalue one-line-hidden">
                  原价:{{ mathManage.formatMoney(item.product.productFace, 2) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="time-tip">
          <div class="tip-title">
            <van-icon
              name="clock-o"
              style="margin-right: 0.12rem"
            />到账时间确认
          </div>
          <div class="tip-text">
            由于参与活动人数较多，充值后预计最迟在<span>72小时内到账</span>，请耐心等待。
          </div>
        </div>
      </div>
    </div>
    <div class="exchange-details">
      <div class="exchange-details-top">
        <h4>温馨提示</h4>
      </div>
      <div
        v-if="productInfo.productDetail"
        class="exchange-details-bottom"
        v-html="productInfo.productDetail"
      ></div>
      <div v-else class="exchange-details-bottom">
        <div class="no-data">
          <img src="../../assets/images/no-detail.png" alt="" />
          <span>抱歉，当前商品暂无详情</span>
        </div>
      </div>
    </div>
    <div class="exchange-btn">
      <div
        v-if="selectCouponData && confirmInfo && confirmInfo.orderRealMoney"
        class="price-tip-box"
      >
        预计券后购买价格为：￥{{
          mathManage.formatMoney(confirmInfo.orderRealMoney, 2)
        }}
      </div>
      <div class="pay-box">
        <div class="service-box">
          <div
            v-for="(item, i) in serviceList"
            :key="i"
            class="service-item service-online"
            @click="serviceAction(item)"
          >
            <a :href="item.href">
              <img class="icon" :src="item.src" alt="" />
              <span class="tip">{{ item.name }}</span>
            </a>
          </div>
        </div>
        <div
          class="pay-btn"
          @click.stop="toPay"
          :style="{
            background: buyDisabled ? '' : 'rgba(255, 58, 32,0.4)',
          }"
        >
          {{ "立即购买" }}
          <div class="buy-tag" v-if="confirmInfo && confirmInfo.couponPrice">
            立省{{ mathManage.formatMoney(confirmInfo.couponPrice, 2) }}元
          </div>
        </div>
      </div>
    </div>

    <van-popup
      v-model="showTicket"
      position="bottom"
      :style="{ height: '75%' }"
      overlay-class="ticket-popup"
      round
    >
      <div class="ticket-box">
        <div class="ticket-title">选择卡券</div>
        <div class="ticket-list" v-if="couponList && couponList.length > 0">
          <div
            class="ticket-item"
            v-for="item in couponList"
            :key="item.couponId"
          >
            <div class="left-box">
              <div class="ticket-price-box">
                <div class="tag">商品抵扣券</div>
                <div class="ticket-price one-line-hidden">
                  <span class="unit">￥</span>
                  <span>{{ mathManage.formatMoney(item.amount, 2) }}</span>
                </div>
              </div>
              <div class="ticket-info-box">
                <div class="name one-line-hidden">{{ item.couponName }}</div>
                <div class="info one-line-hidden">
                  {{ item.specName }};{{ item.attrName }}
                </div>
                <div class="time">有效期至{{ item.endDate }}</div>
              </div>
            </div>
            <div class="right-box" @click="selectCouponClick(item)">
              <van-icon v-if="item.checked" class="active" name="passed" />
              <span v-else class="circle"></span>
            </div>
          </div>
        </div>
        <div v-if="!couponList || couponList.length == 0" class="no-data">
          <img :src="require('../../assets/images/no-card.png')" alt="" />
          <span class="no-text">抱歉，当前暂无卡券</span>
        </div>
        <div class="ticket-action">
          <span class="btn" @click="confimSelect">完成</span>
        </div>
      </div>
    </van-popup>

    <login-box
      v-model="showLogin"
      @onOk="loginOk"
      @handleCancel="hiddenLogin"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import {
  Cell,
  CellGroup,
  ActionSheet,
  Icon,
  Field,
  Popup,
  Dialog,
  Popover,
} from "vant";
import mathManage from "@/utils/mathManage";
import {
  GetRechargeAccountHistory,
  getChargeFacevalue,
  GetAvailableCoupons,
  confirmOrder,
  unifysendorder,
  getDigitaldetail,
} from "@/api/common";
import LoginBox from "@/components/LoginBox.vue";
const regTel = /^(?:(?:\+|00)86)?1\d{10}$/;
export default {
  name: "FastBill",
  components: {
    LoginBox,
    [Field.name]: Field,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [ActionSheet.name]: ActionSheet,
    [Icon.name]: Icon,
    [Popup.name]: Popup,
    [Dialog.name]: Dialog,
    [Popover.name]: Popover,
  },
  props: {
    productId: {
      default: "",
    },
  },
  data() {
    return {
      mathManage,
      showLogin: false,
      mobile: "",
      mobileAddress: "",
      billList: [],
      selectCouponData: null, // 选择的优惠券
      couponList: [], // 优惠券列表
      confirmInfo: null,
      submitDisabled: false,
      selectProperty: {}, // 慢充 选择的运营商
      selectAttr: {}, // 慢充 选择的面额
      productInfo: {}, // 慢充详情
      propertyList: [], // 慢充商品列表
      attrList: [], // 慢充面额列表
      showTicket: false,
      sp: "", // 运营商
      accountHistoryList: [],
      showPopover: false,
    };
  },
  computed: {
    ...mapState(["isLogin", "isInWx", "serviceList"]),
    // 计算立即购买按钮是否可以点击
    buyDisabled: {
      get() {
        if (!this.mobile) return false;
        return this.selectProperty.specId && this.selectAttr.attrId;
      },
      set() {},
    },
    // 计算可用卡券数量
    couponCount: {
      get() {
        return this.couponList.length;
      },
      set() {},
    },
  },
  watch: {
    mobile: function (val) {
      this.mobile = val.replace(/\D/g, "");
    },
    showPopover(newVal, oldVal) {
      if (newVal && this.accountHistoryList.length > 0) {
        this.showPopover = true;
      } else {
        this.showPopover = false;
      }
    },
  },
  mounted() {
    document.title = "话费充值";
    this.mobile = this.$route.query.tel || "";
    this.getBillList();

    this.isLogin && this.getHistoryAccount();
  },
  methods: {
    ...mapActions(["serviceAction"]),

    inputBlur() {
      document.body.scrollIntoView(); // 回顶部
    },

    // 获取历史账号
    async getHistoryAccount() {
      const res = await GetRechargeAccountHistory({
        productType: 30,
      });
      if (res && res.code == 0) {
        this.accountHistoryList = res.data?.list || [];
        if (!this.mobile) {
          this.mobile = (res.data?.list || [])[0]?.recharge || "";
          this.$nextTick(() => {
            this.mobile && this.getChargeList();
          });
        } else {
          this.getChargeList();
        }
      }
    },

    // 选择历史账号
    selectAccount(data) {
      this.mobile = data.recharge;
      this.showPopover = false;
      this.getChargeList();
    },

    // 删除历史账号
    delAccount(data) {
      this.accountHistoryList = this.accountHistoryList.filter(
        (item) => item.recharge != data.recharge
      );
      this.$nextTick(() => {
        if (this.accountHistoryList.length == 0) {
          this.showPopover = false;
        }
      });
    },

    // 获取话费列表 慢充
    async getBillList() {
      const res = await getDigitaldetail({
        SlowMobileProduct: true,
        productId: this.productId,
      });
      if (res && res.code == 0) {
        this.productInfo = res.data || {};
        // 容错 避免数据错误
        const list = (res.data?.specs || []).map((item) => {
          return {
            ...item,
            attrs: (item.attrs || []).map((el) => {
              return {
                ...el,
                product: el.product || {},
              };
            }),
          };
        });

        this.propertyList = list;
        const selectProperty = list.find((item) => item.isChecked) || {};
        this.selectProperty = selectProperty;
        const attrList = selectProperty.attrs || [];
        this.attrList = attrList;
        this.selectAttr = attrList.find((item) => item.isChecked) || {};

        // 获取慢充优惠券列表
        this.$nextTick(() => {
          this.getCouponList(this.selectAttr.product.tenantProductId);
          this.autoSelectSpec();
        });
      } else {
        this.$toast(res.message);
      }
    },

    // 填写手机号
    changeTel(val) {
      this.billList = this.billList.map((item) => {
        return {
          ...item,
          isCoupon: false,
        };
      });
      if (!val || val.length != 11) {
        this.mobileAddress = "";
        return;
      }
      if (!regTel.test(val.replace(/\D/g, ""))) {
        this.mobileAddress = "手机号格式错误";
        return;
      }
      this.getChargeList();
    },

    async getChargeList() {
      const res = await getChargeFacevalue({
        mobile: this.mobile.replace(/ /g, ""),
      });

      if (res && res.code == 0) {
        this.mobileAddress = `${res.data.province || ""} ${
          res.data.city || ""
        } ${res.data.sp || ""}`;
        this.sp = res.data.sp;

        this.autoSelectSpec();
      } else {
        this.$toast(res.message);
      }
    },

    // 选择优惠券按钮
    clickCouponCell() {
      this.showTicket = true;
    },

    // 获取卡券列表
    async getCouponList(id) {
      if (!this.isLogin) return;
      let params = {
        ProductId: id,
      };
      try {
        const res = await GetAvailableCoupons(params);
        if (res && res.code == 0) {
          const list = (res.data?.list || []).map((item, i) => {
            return {
              ...item,
              checked: i == 0,
            };
          });
          this.couponList = list;
          this.selectCouponData = list.find((item) => item.checked);
          this.$nextTick(() => {
            this.confirmOrder();
          });
        } else {
          this.$message.error(res.message);
          this.confirmOrder();
        }
      } catch (error) {
        this.confirmOrder();
      }
    },

    // 点击卡券
    selectCouponClick(data) {
      this.couponList = this.couponList.map((item) => {
        if (item.cardNumber == data.cardNumber) {
          return {
            ...item,
            checked: !item.checked,
          };
        }
        return {
          ...item,
          checked: false,
        };
      });
    },

    // 确定选择
    confimSelect() {
      this.selectCouponData = this.couponList.find((item) => item.checked);
      this.showTicket = false;
      this.$nextTick(() => {
        this.confirmOrder();
      });
    },

    // 确认订单
    async confirmOrder() {
      this.$loading("请稍等...");
      this.confirmInfo = null;
      const ticketObj = this.couponList.find((item) => item.checked) || {};
      try {
        const res = await confirmOrder({
          tenantProductId: this.selectAttr.product.tenantProductId,
          buyNum: 1,
          cardNumber: ticketObj.cardNumber,
          chargeAccount: this.mobile,
        });
        this.$toast.clear();
        if (res && res.code == 0) {
          this.confirmInfo = res.data;
          this.submitDisabled = false;
        } else {
          this.$toast(res.message);
        }
      } catch (error) {
        this.$toast.clear();
      }
    },

    // 登录完成
    loginOk() {
      this.showLogin = false;
      this.toPay();
    },

    hiddenLogin() {
      this.showLogin = false;
    },

    // 去充值
    toPay() {
      if (!this.isLogin) {
        if (this.isInWx) {
          this.$wxLogin();
        } else {
          this.showLogin = true;
        }
        return;
      }
      if (!this.buyDisabled) return;
      if (this.submitDisabled) return;
      if (!this.selectProperty.specName.includes(this.sp)) {
      }

      const ticketObj = this.couponList.find((item) => item.checked) || {};
      const params = {
        productId: this.selectAttr.product.tenantProductId,
        buyNum: 1,
        cardNumber: ticketObj.cardNumber || null,
        useIntetral: false,
        chargeAccount: this.mobile,
      };

      if (!this.selectProperty.specName.includes(this.sp)) {
        Dialog.confirm({
          title: "警告",
          message: "手机号与运营商不匹配，是否继续？",
        })
          .then(async () => {
            this.goBuy(params);
          })
          .catch(() => {
            // on cancel
          });
      } else {
        this.goBuy(params);
      }
    },

    async goBuy(params) {
      this.$loading("请稍等...");
      this.submitDisabled = true;
      try {
        const res = await unifysendorder(params);
        this.$toast.clear();
        if (res && res.code == 0) {
          if (res.data.isNeedToPay) {
            this.$router.push(`/mall/cashierDesk?id=${res.data.orderId}`);
          } else {
            this.$router.push(`/mall/orderDetails/${res.data.orderId}`);
          }
        } else {
          this.submitDisabled = false;
          this.$toast(res.message);
        }
      } catch (error) {
        this.$toast.clear();
        this.submitDisabled = false;
      }
    },

    // 慢充 列表 切换运营商
    changeProperty(data) {
      const { specId } = data;
      if (specId == this.selectProperty.specId) return;
      this.selectProperty = data;
      const { attrs = [] } = data;
      this.attrList = attrs;
      if (attrs.length > 0) {
        this.selectAttr = attrs[0];
      }
      this.$nextTick(() => {
        const { product } = this.selectAttr;
        this.getCouponList(product.tenantProductId);
      });
    },

    // 慢充 列表 切换面额
    changeAttr(data) {
      const { attrId } = data;
      if (attrId == this.selectAttr.attrId) return;
      this.selectAttr = data;
      this.getCouponList(data.product.tenantProductId);
    },

    // 慢充 关闭选择
    closeTicket() {
      this.showTicket = false;
    },

    // 按照运营商自动选择规格属性
    autoSelectSpec() {
      if (this.sp && this.propertyList.length > 0) {
        const list = this.propertyList.map((item) => {
          return {
            ...item,
            isChecked: item.specName.includes(this.sp),
          };
        });
        this.propertyList = list;
        const selectProperty = list.find((item) => item.isChecked) || {};
        this.selectProperty = selectProperty;
        const attrList = selectProperty.attrs || [];
        this.attrList = attrList;
        this.selectAttr = attrList[0] || {};
        this.getCouponList((attrList[0] || {}).product.tenantProductId);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.phone-bill {
  height: 100%;
  overflow-y: auto;
  // background-color: #fff;

  .tab-change {
    height: 0.96rem;
    display: flex;
    background-color: #fff;

    .tab-item {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .name {
        font-size: 0.3rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }

      .line {
        width: 0.48rem;
        height: 0.04rem;
        // background: linear-gradient(138deg, #ff9f6c 0%, #ff5e65 100%);
        border-radius: 0.04rem;
        margin-top: 0.16rem;
      }

      &.active {
        .name {
          color: #000000;
        }
        .line {
          background: linear-gradient(138deg, #ff9f6c 0%, #ff5e65 100%);
        }
      }
    }
  }

  .container {
    padding: 0 0.2rem;
    box-sizing: border-box;
    margin-top: 0.24rem;

    .bill-content {
      padding: 0.24rem 0.2rem;
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 0.16rem;
    }

    .top-tip {
      font-size: 0.22rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;

      span {
        color: #ff503f;
      }
    }

    .phone-box {
      border-bottom: 0.01rem solid #e8e8e8;

      .content-box {
        width: 100%;
        // padding: 0 0 0.02rem 0;
        display: flex;
        align-items: center;
      }

      .tel-tip {
        font-size: 0.2rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        margin-bottom: 0.04rem;
      }

      .left-box {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        .tel-input {
          /deep/ .van-cell {
            padding: 0;
            margin: 0;
          }
          /deep/ .van-field__control {
            font-size: 0.4rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
          /deep/ .van-field__clear {
            font-size: 0.32rem;
          }
        }

        .account-popover {
          // width: 100%;
        }
      }

      .right-box {
        width: 0.8rem;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0.4rem;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
    }

    .phone_tip {
      font-size: 0.2rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ff4800;
      margin-top: 0.08rem;
    }

    .bill-list {
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      margin-top: 0.2rem;

      .bill-item {
        width: 33.33333333333%;
        height: 1.4rem;
        border-radius: 0.16rem;
        margin-bottom: 0.2rem;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        .content-item {
          width: 2.16rem;
          height: 100%;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: #f9f9f8;
          position: relative;
          border: 0.02rem solid #fff;
          border-radius: 0.16rem;

          .coupon-tag {
            position: absolute;
            left: -0.02rem;
            top: -0.02rem;
            width: 0.6rem;
            height: 0.32rem;
          }

          .price {
            font-size: 0.36rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #c8c8c8;
          }

          .coupon-price {
            font-size: 0.24rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #c8c8c8;
            margin-top: 0.04rem;
          }
        }

        .is-coupon {
          .price {
            color: #333333;
          }
          .coupon-price {
            color: #999999;
          }
        }

        .active {
          background: rgba(255, 89, 62, 0.1);
          border-color: #ff593e;
          box-sizing: border-box;
          overflow: hidden;

          .price {
            color: #ff593e;
          }
          .coupon-price {
            color: #ff593e;
          }
        }
      }
    }

    .product-property {
      background: #ffffff;
      border-radius: 0.16rem;
      // padding: 0.24rem 0.24rem 0.4rem;
      box-sizing: border-box;

      .title {
        font-size: 0.26rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;

        &.facevalue-title {
          margin-top: 0.2rem;
        }
      }

      .property-list {
        margin-top: 0.14rem;
        display: flex;
        flex-wrap: wrap;

        .property-item-box {
          width: 33.3333%;
          // height: 0.68rem;
          // overflow: hidden;
          display: flex;
          margin-bottom: 0.12rem;

          &:nth-child(2n) {
            justify-content: center;
          }

          &:nth-child(3n) {
            justify-content: flex-end;
          }

          .property-item {
            width: 93%;
            height: 100%;
            overflow: hidden;
            background: #fff;
            border-radius: 0.08rem;
            border: 0.01rem solid #d8d8d8;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.26rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            padding: 0.1rem 0;
            color: #333333;
            box-sizing: border-box;
            position: relative;

            .property-item-icon {
              position: absolute;
              right: 0;
              bottom: 0;
              width: 0.36rem;
              height: 0.36rem;
              display: none;
              align-items: center;
              justify-content: center;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          &.active {
            .property-item {
              background: #faefed;
              border-color: #ff6d67;
              color: #ff2c27;
              font-family: PingFangSC-Medium, PingFang SC;

              font-weight: 500;

              .property-item-icon {
                display: flex;
              }
            }
          }
        }
      }

      .attr-list {
        margin-top: 0.14rem;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        .attr-item-box {
          width: 33.3333%;
          height: 2.08rem;
          margin-bottom: 0.12rem;
          // overflow: hidden;

          &:nth-child(2n) {
            justify-content: center;
          }

          &:nth-child(3n) {
            justify-content: flex-end;
          }

          .attr-item {
            width: 93%;
            height: 100%;
            background: #f8f8f7;
            border: 0.01rem solid #f8f8f7;
            border-radius: 0.12rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            padding: 0 0.04rem;
            box-sizing: border-box;
            position: relative;

            .item {
              text-align: center;
            }

            .attr-tag {
              position: absolute;
              top: 0;
              left: 0;
              height: 0.32rem;
              background: linear-gradient(138deg, #ff9f6c 0%, #ff5e65 100%);
              border-radius: 0.12rem 0rem 0.12rem 0rem;
              font-size: 0.2rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0.06rem 0.1rem;
            }

            .attr-name {
              font-size: 0.26rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              margin-top: 0.12rem;
            }

            .attr-price {
              width: 100%;
              font-size: 0.4rem;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
              margin: 0.02rem 0 0.04rem;

              .unit {
                font-size: 0.22rem;
              }
            }

            .attr-facevalue {
              font-size: 0.22rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #b0b1b3;
              text-decoration: line-through;
            }

            .attr-item-icon {
              position: absolute;
              right: 0;
              bottom: 0;
              width: 0.36rem;
              height: 0.36rem;
              display: none;
              align-items: center;
              justify-content: center;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          &.active {
            .attr-item {
              background: #faefed;
              border: 0.01rem solid #ff6d67;

              .attr-name {
                color: #ff2c27;
                font-family: PingFangSC-Medium, PingFang SC;
              }

              .attr-price {
                color: #ff2c27;
              }

              .attr-item-icon {
                display: flex;
              }
            }
          }
        }
      }
    }

    .bill-coupon {
      margin-top: 0.2rem;
      .van-cell__title {
        color: #565656 !important;
      }
      /deep/ .van-cell {
        padding: 10px 0 10px 0;
      }
      /deep/ .van-cell::after {
        border: 0 !important;
      }
      .left-icon {
        width: 0.32rem;
        height: 0.28rem;
        margin-right: 0.14rem;
        margin-top: 0.07rem;
      }
      .coupon-cell {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .amount {
          color: #ff4800;
        }

        .tip {
          color: #969799;
        }
        .arrow-tip {
          margin-left: 0.2rem;
        }
      }
    }

    .time-tip {
      margin-top: 0.2rem;

      .tip-title {
        font-size: 0.22rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        display: flex;
        align-items: center;
      }

      .tip-text {
        font-size: 0.2rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        margin-top: 0.08rem;
        span {
          color: #ff5b42;
        }
      }
    }
  }

  .exchange-details {
    margin-top: 0.24rem;
    background-color: #fff;
    padding: 0 0.3rem;
    margin-bottom: 2rem;

    .exchange-details-top {
      display: flex;
      flex-direction: column;
      align-items: center;

      padding: 0.28rem 0;
      > h4 {
        text-align: center;
        font-size: 0.26rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }

      .line {
        width: 0.8rem;
        height: 0.04rem;
        background: #ff7953;
        margin-top: 0.12rem;
      }
    }

    .exchange-details-bottom {
      min-height: 4rem;
      padding-bottom: 1rem;
      font-size: 0.28rem;

      .no-data {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
          width: 2.8rem;
          height: 2.16rem;
          margin-top: 0.8rem;
        }

        span {
          margin-top: 0.4rem;
          font-size: 0.24rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
      }
    }

    .exchange-details-text {
      margin-top: 0.2rem;
      > p {
        font-size: 0.28rem;
        font-weight: 400;
        color: #9b9b9b;
        line-height: 0.5rem;
      }
    }
  }

  .exchange-btn {
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: #fff;

    .price-tip-box {
      height: 0.56rem;
      background: #010100;
      display: flex;
      align-items: center;
      font-size: 0.26rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffdab0;
      padding-left: 1.6rem;
      box-sizing: border-box;
    }
    .pay-box {
      height: 1rem;
      display: flex;
      align-items: center;
      padding: 0 0.4rem;
      box-sizing: border-box;

      .service-box {
        display: flex;

        .service-item {
          // width: 0.96rem;
          margin-right: 0.29rem;

          a {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
          }

          .icon {
            width: 0.4rem;
            margin-top: 0.02rem;
          }

          .tip {
            font-size: 0.2rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
          }
        }
      }
      .pay-btn {
        flex: 1;
        height: 0.8rem;
        margin: 0 auto;
        background: linear-gradient(90deg, #ff9577 0%, #ff3a20 100%);
        border-radius: 0.4rem;
        font-size: 0.32rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 0.01rem;
        position: relative;

        .buy-tag {
          top: -0.4rem;
          right: -0.2rem;
          position: absolute;
          height: 0.52rem;
          padding: 0 0.2rem;
          background: linear-gradient(90deg, #e6ba87 0%, #dba163 100%);
          border-radius: 1.8rem 2rem 2rem 0rem;
          font-size: 0.24rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .select-ticket-box {
    .ticket-list {
      height: 50vh;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding-bottom: 0.2rem;

      .table-list {
        flex: 1;
        overflow-y: auto;
        padding: 0 0.16rem 0 0.4rem;

        .list-item {
          height: 1.48rem;
          overflow: hidden;
          display: flex;

          .check-icon {
            height: 100%;
            width: 0.8rem;
            display: flex;
            align-items: center;
            justify-content: center;

            .icon-checked {
              width: 0.27rem;
              height: 0.27rem;
              background: linear-gradient(90deg, #ff9577 0%, #ff3a20 100%);
              border-radius: 50%;
              overflow: hidden;
              display: flex;
              align-items: center;
              justify-content: center;

              .icon {
                font-size: 0.16rem;
                color: #fff;
              }
            }

            .icon-nocheck {
              width: 0.27rem;
              height: 0.27rem;
              background: #f4f4f4;
              border: 0.02rem solid #d8d8d8;
              border-radius: 50%;
            }
          }

          .item-info {
            flex: 1;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            .item-name {
              font-size: 0.28rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #303133;
              padding: 0.02rem 0;
              display: flex;
              align-items: center;

              span.coupon-name {
                margin-right: 0.2rem;
                flex: 1;
              }
              span.product-name {
                padding: 0.02rem 0.06rem 0.03rem 0.06rem;
                border-radius: 0.04rem;
                border: 0.01rem solid #fb5f2f;
                font-size: 0.24rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #fb5f2f;
                margin-right: 0.1rem;
              }
            }
            .item-time {
              font-size: 0.24rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #9b9b9b;
              margin-top: 0.16rem;
            }

            .item-name-nocheck {
              > span:first-child {
                margin-right: 0.2rem;
                color: #9b9b9b;
              }
              > span.coupon-name {
                display: inline-block;
                border: 0.01rem solid #d8d8d8;
                color: #9b9b9b;
                background: #f4f4f4;
              }
            }
          }
        }
      }

      .action-box {
        padding: 0 0.48rem;
        margin-top: 0.2rem;

        .action-btn {
          height: 0.8rem;
          background: linear-gradient(90deg, #ff9577 0%, #ff3a20 100%);
          border-radius: 0.4rem;
          font-size: 0.36rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          letter-spacing: 0.04rem;
        }
      }

      .no-data {
        height: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          width: 2.26rem;
          height: 2.16rem;
          border-radius: 50%;
          margin-top: 0.4rem;
        }

        .no-text {
          margin-top: 0.24rem;
          font-size: 0.24rem;
          font-family: PingFangSC-Regular, PingFang SC;
          color: #999;
        }
      }
    }
  }

  .ticket-box {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 0.36rem 0.24rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .ticket-title {
      height: 0.6rem;
      font-size: 0.36rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      text-align: center;
      margin-bottom: 0.34rem;
    }

    .ticket-list {
      flex: 1;
      overflow-y: auto;

      .ticket-item {
        overflow: hidden;
        display: flex;
        margin-bottom: 0.32rem;

        .left-box {
          flex: 1;
          display: flex;
          background: #fdf6f0;
          border-radius: 0.16rem;
        }

        .right-box {
          width: 0.32rem;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 0.32rem;

          .circle {
            width: 0.32rem;
            height: 0.32rem;
            background: #ffffff;
            border: 0.02rem solid #e6e6e6;
            border-radius: 50%;
          }
          .active {
            font-size: 0.32rem;
            color: #ff5b42;
            font-weight: 500;
          }
        }

        .ticket-price-box {
          width: 1.76rem;
          position: relative;
          padding: 0.4rem 0;

          .tag {
            position: absolute;
            top: 0;
            width: 100%;
            height: 0.3rem;
            background: #fee5e5;
            border-radius: 0.16rem 0rem 0.16rem 0rem;
            font-size: 0.2rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #fe0000;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .ticket-price {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.48rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #fe0000;
            padding-top: 0.1rem;
            box-sizing: border-box;

            .unit {
              font-size: 0.28rem;
            }
          }
        }

        .ticket-info-box {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0 0.12rem;
          box-sizing: border-box;
          overflow: hidden;

          .name {
            width: 100%;
            font-size: 0.26rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ff1616;
          }

          .info {
            display: flex;
            flex-wrap: wrap;
            font-size: 0.2rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #a1a1a1;
            margin-top: 0.08rem;
          }
          .time {
            font-size: 0.2rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #a1a1a1;
            margin-top: 0.08rem;
          }
        }
      }
    }
    .ticket-action {
      height: 1.26rem;
      background-color: #fff;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      .btn {
        width: 100%;
        height: 0.8rem;
        background: #ff5b42;
        border-radius: 0.4rem;
        font-size: 0.32rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .no-data {
      height: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 2.26rem;
        height: 2.16rem;
        border-radius: 50%;
        margin-top: 0.4rem;
      }

      .no-text {
        margin-top: 0.24rem;
        font-size: 0.24rem;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #999;
      }
    }
  }
}
</style>

<style lang="less">
.account-history {
  width: 90vw;
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.5);

  .list-box {
    background-color: #fff;
    padding: 0.2rem 0.2rem 0.1rem;

    .list-item {
      margin-bottom: 0.1rem;
      display: flex;
      align-items: center;
      overflow: hidden;

      .text {
        flex: 1;
      }

      .icon {
        width: 1rem;
        text-align: right;
      }
    }
  }
}
</style>

<template>
  <div class="phone-bill">
    <div class="tab-change">
      <div
        :class="['tab-item', active == 1 ? 'active' : '']"
        @click="changeType(1)"
      >
        <span class="name">话费充值</span>
        <span class="line"></span>
      </div>
      <div
        :class="['tab-item', active == 2 ? 'active' : '']"
        @click="changeType(2)"
      >
        <span class="name">慢充话费</span>
        <span class="line"></span>
      </div>
    </div>
    <fast-bill v-if="active == 1" />
    <slow-bill v-if="active == 2" :productId="productId" />
  </div>
</template>

<script>
import FastBill from "./components/FastBill.vue";
import SlowBill from "./components/SlowBill.vue";

export default {
  name: "PhoneBill",
  components: {
    FastBill,
    SlowBill,
  },
  data() {
    return {
      active: 1, // 1-快充  2-慢充
      productId: "",
    };
  },
  created() {
    document.title = "话费充值";
    this.mobile = this.$route.query.tel || "";
    this.active = this.$route.query.productType == 40 ? 2 : 1;
    this.productId = this.$route.query.productId || "";
  },
  methods: {
    // 切换tab
    changeType(active) {
      if (this.active == active) return;
      this.productId = "";
      this.active = active;
    },
  },
};
</script>

<style lang="less" scoped>
.phone-bill {
  height: 100%;
  overflow-y: auto;
  // background-color: #fff;

  .tab-change {
    height: 0.96rem;
    display: flex;
    background-color: #fff;

    .tab-item {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .name {
        font-size: 0.3rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }

      .line {
        width: 0.48rem;
        height: 0.04rem;
        // background: linear-gradient(138deg, #ff9f6c 0%, #ff5e65 100%);
        border-radius: 0.04rem;
        margin-top: 0.16rem;
      }

      &.active {
        .name {
          color: #000000;
        }
        .line {
          background: linear-gradient(138deg, #ff9f6c 0%, #ff5e65 100%);
        }
      }
    }
  }
}
</style>